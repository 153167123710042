// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use "./styles/dark";
@use "./styles/light";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600&display=swap');
@import "./theme";
@import '@angular/material/theming';
mat.$theme-ignore-duplication-warnings: true;
// Plus imports for other components in your app.

// Include the global styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
//@include mat.core();
//
//@include mat.core-theme(dark.$dark-theme);
//@include mat.button-theme(dark.$dark-theme);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes(dark.$dark-theme);


/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: 'Open Sans', Roboto, "Helvetica Neue", sans-serif; }
h1, h2, h3, h4, h5, h6, p, a, button { font-family: 'Open Sans', sans-serif !important;}
h1, h2, h3, h4, h5, h6 {font-weight: 300 !important;}
body {
  font-size: 15px !important;
}
.loading-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
img {
  pointer-events: none;
}


/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1c1c1c;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1f1f1f;
}
